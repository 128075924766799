<template>
	<v-chip v-bind="$attrs" v-on="$listeners" label outlined :color="color">
		<span>{{ (value || "").toUpperCase() }}</span>
		<slot />
	</v-chip>
</template>

<script>
export default {
	props: ["value"],
	data() {
		return {
			color: null,
		};
	},
	async mounted() {
		if (this.value)
			this.color = this.$store.getters.getColoresEmpresas[this.value.toLowerCase()];
	},
};
</script>

<style>
</style>